

$(document).ready(function(){

    $.ajaxSetup({
        headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
    });

    $('#send-contact-form').on('click', function(){

            if( $("#customColorCheck1").prop('checked') == false){

                $.alert({
                    type: "red",
                    title: "Error",
                    content: 'Please check the agree box'//result.responseJSON['error'],
                });

                return;
            }

            Swal.showLoading();
            xhr = $.ajax({
                url: "/send-contact",
                method: "POST",
                dataType: "JSON",
                data: {
                    name: $("input[name='name']").val(),
                    phone: $("input[name='phone']").val(),
                    email: $("input[name='email-contact']").val(),
                    suggest: $("select[name='suggest']").val(),
                    message: $("textarea[name='question']").val(),
                    customCheck: $("#customColorCheck1").prop('checked'),
                    ref: $("input[name='ref']").val(),
                },
                success: function(result) {
                    Swal.close();
                    $.alert({
                        type: "blue",
                        title: "Success",
                        content: "Thành công",
                    });
                },
                error: function(result) {
                    Swal.close();
                    $.alert({
                        type: "red",
                        title: "Error",
                        content: 'Invalid data'//result.responseJSON['error'],
                    });
                    if (xhr.status == 403);
                    {
                        /*window.alert(forbiddenText);*/
                    }
                },
            });
        });

});
